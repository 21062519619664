<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        lg="8"
        md="10"
        xl="6"
      >
        <v-tabs
          v-model="active"
          background-color="#ebebeb"
          centered
          grow
          show-arrows
          slider-color="primary"
        >
          <v-tab ruid="tab-units">
            Units
          </v-tab>
          <v-tab ruid="tab-company-admins">
            Company Admins
          </v-tab>
          <v-tab
            v-if="company.features[FEATURE_NAME.MULTI_ENTITY]"
            ruid="tab-unit-admins"
          >
            Unit Admins
          </v-tab>
          <v-tab ruid="tab-coaches">
            Coaches
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="active">
          <v-tab-item>
            <form-units
              :company="company"
              @saved="unitsSaved"
            >
              <p class="info-header">
                Units are a way of structuring and managing users in the platform. Units
                can be defined as different departments, locations, teams etc. in your
                company. Units are chosen when registering on the platform.
              </p>
            </form-units>
          </v-tab-item>
          <v-tab-item>
            <admin-user-list
              :admin-user-emails="company.admins || []"
              :check-if-emails-exists="checkIfEmailsExists"
              :domains="company.domain"
              :is-loading="loading.admins"
              :list-item-actions="listItemActions.admins"
              :min-count="1"
              label="Company Admins"
              @save="save(ADMIN_ROLES.COMPANY_ADMINS, $event)"
            >
              <p class="info-header">
                Company Admins have full access to the administration of the platform including
                all projects of the entire organization.
              </p>
            </admin-user-list>
          </v-tab-item>
          <v-tab-item
            v-if="company.features[FEATURE_NAME.MULTI_ENTITY]"
          >
            <admin-user-list
              :admin-user-emails="company.unitAdmins || []"
              :check-if-emails-exists="checkIfEmailsExists"
              :domains="company.domain"
              :is-loading="loading.unitAdmins"
              :list-item-actions="listItemActions.unitAdmins"
              label="Unit Admins"
              @save="save(ADMIN_ROLES.UNIT_ADMINS, $event)"
            >
              <p class="info-header">
                Unit Admins only get notified and have access to project and user
                information of the unit they are assigned to.
              </p>
            </admin-user-list>
          </v-tab-item>
          <v-tab-item>
            <coaching-tabs
              :coaches="coaches"
              :check-if-emails-exists="checkIfEmailsExists"
              :domains="company.domain"
              :loading="loading.coaches"
              :list-item-actions="listItemActions.coaches"
              @save="saveCoaches($event.type, $event.emailsToUpdate)"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AdminUserList, AdminUsersMixin, FormUnits, CoachingTabs } from '@kickbox/common-admin';
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import { ADMIN_ROLES } from '@kickbox/common-util/constants/admin-roles';

export default {
  name: 'AdminUsers',
  components: {
    AdminUserList,
    CoachingTabs,
    FormUnits
  },
  mixins: [AdminUsersMixin],
  data() {
    return {
      active: 0,
      FEATURE_NAME,
      ADMIN_ROLES
    };
  },
  methods: {
    updateStore(adminUsers, adminUserType) {
      this.$store.commit('setAdminUsers', { adminUsers, adminUserType });
    },
    unitsSaved() {
      this.$store.dispatch('showSnackBar', { text: 'The units have been saved.' });
    }
  }
};
</script>

<style lang="scss" scoped>
.info-header {
  font-size: 13px;
  font-weight: 500;
  color: #4D4D4D;
}
</style>
